.flex-news-all{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around; 
    /* max-width: 1150px; */
    max-width: 1100px;
    margin: 0 auto;
    background-color: rgb(255, 255, 255);
    
}
.box-news-all{   
    display: flex;
    justify-content: space-around;
    margin-top: 2vh ;
    margin-right: 2vh;
    width: 40%;
}
.card-news-all{
    display: flex;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    width: 491px;
    height: 140px;
    border-radius: 15px;
    padding-right: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 1px 15px;
}
.card-news-all-txt.card-txt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.card-news-all:hover,
.card-news-all:focus {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
  color: #444;
}
.card-news-all-img{
    width: 25%;
    padding: 14px;
    padding-right: 0px; 
    align-items: center;
    
}
.img-news-all{

    
    width:100px;
    height:100px;
    border-radius: 15px;
}
.card-bottom {
    display: flex;
}
.card-views-all {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    padding-right: 10px;
    margin: 0px;
    align-items: center;
    width: 278px;
}

.card-news-all-txt{
    padding: 14px;
    padding-bottom: 0px;
}
@media screen and (max-width: 400px) {
   
    .flex-news-all{
    
        max-width: 490px; 
        
    } 
    .box-news-all{
        min-width: 290px; 
      
          
    }
    .card-news-all{ 
        min-width: 90px;     
    }
   
   
}

